import '../style/componentStyles/footer.scss'


const Footer = () => {
    return (
        <>
            <div className="footer-container">
                <h2>Copyright © 2023. All rights are reserved
</h2>
            </div>
        </>
    )
}

export default Footer;