import '../style/componentStyles/navbar.scss'
import { useState } from 'react'


const Navbar = () => {

    const [hamburger, setHamburger] = useState(false);

    const hamburgerMenu = () => {
        setHamburger(!hamburger);
    };

    const navLinks = [
        {
            name: "Home",
            link: "#home",
        },
        {
            name: "About",
            link: "#about"
        },

        {
            name: "Projects",
            link: "#projects",
        },
        {
            name: "Contact",
            link: "#contact",
        }
    ]

    const scrollUp = () => {
        window.scrollTo({top: (0,0), behavior: "smooth"})
    }

    return (
        <>
            <nav>
                <h1 className="header-logo" onClick={scrollUp}>Krizak.dev</h1>
                <ul>
                {navLinks.map((item) => (
                    <li key={item.name}>
                        <a href={item.link}>{item.name}</a>
                    </li>
                ))}
                <li onClick={() => hamburgerMenu()} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" className="hamburger-menu">
                        <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM64 256c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H96c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
                    </svg>
                </li>
            </ul>
            </nav>
            <div className={`mobile-nav ${hamburger ? "open-menu" : "closed-menu"}`}>
                <span onClick={() => hamburgerMenu()}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 384 512">
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </span>

                <ul>
                    {navLinks.map((item) => (
                        <li key={item.name} onClick={() => hamburgerMenu()}>
                            <a href={item.link}>{item.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default Navbar;